import { isEmpty, omit } from 'ramda';
import camelCaseKeys from 'src/api/camelcase-keys';
import {
  CriteriaUnifiedAttributes,
  CriteriaUnifiedTargeting,
} from 'src/interactions/newinteractions/targeting/multi-app-segment-builder/types';
import { transformCriteriaForApp } from 'src/interactions/newinteractions/targeting/multi-app-segment-builder/util/transform-criteria';
import { AppInteractionData, IApplication, TargetingCriteria } from 'src/types/core';
import { AppEventList } from '../events';
import { OrganizationApp } from '../organizations';
import { AppIcon } from '../appIcons/appIcons.types';

export enum ImtPermisson {
  CREATOR = 'CREATOR',
  HAS_ACCESS_TO_ALL_APPS = 'HAS_ACCESS_TO_ALL_APPS',
  HAS_ACCESS_TO_SOME_APPS = 'HAS_ACCESS_TO_SOME_APPS',
}

export enum ImtUserRole {
  BILLING_CONTACT = 'BILLING_CONTACT',
  ADMIN_OR_COLLABORATOR = 'ADMIN_OR_COLLABORATOR',
  REPORTER_IN_ALL_APPS = 'REPORTER_IN_ALL_APPS',
  REPORTER_IN_SOME_APPS = 'REPORTER_IN_SOME_APPS',
  MIXED = 'MIXED',
}

export class ImtModel {
  static getIsUserHasFullAccessToApp(
    appId: string,
    organizationApps: OrganizationApp[],
    userApps: IApplication[],
    userPermisson: ImtPermisson,
    userRole: ImtUserRole,
  ) {
    if (userRole === ImtUserRole.BILLING_CONTACT) {
      return true;
    }

    const hasAccessToApp =
      userPermisson === ImtPermisson.HAS_ACCESS_TO_SOME_APPS ? organizationApps.some(({ id }) => id === appId) : true;
    if (!hasAccessToApp) {
      return false;
    }

    const { role = 'reporter' } = userApps.find(({ id }) => id === appId) || {};
    return role !== 'reporter';
  }

  static getIsUserNotReporterWithAccessToAllApps(userPermisson: ImtPermisson, userRole: ImtUserRole) {
    if (userRole === ImtUserRole.BILLING_CONTACT) {
      return true;
    }

    return !(getIsUserHasReporterRole(userRole) || userPermisson === ImtPermisson.HAS_ACCESS_TO_SOME_APPS);
  }

  static getIsUserCanEditTargetedApps(userPermisson: ImtPermisson, userRole: ImtUserRole) {
    if (userRole === ImtUserRole.BILLING_CONTACT) {
      return true;
    }

    const isCreatorOrHasAccessToAllApps =
      userPermisson === ImtPermisson.CREATOR || userPermisson === ImtPermisson.HAS_ACCESS_TO_ALL_APPS;

    const isAdminOrCollabOrMixedRole = userRole === ImtUserRole.ADMIN_OR_COLLABORATOR || userRole === ImtUserRole.MIXED;

    return isCreatorOrHasAccessToAllApps && isAdminOrCollabOrMixedRole;
  }

  static getAppRolesMap(orgId: string, apps: IApplication[] = []) {
    const appRolesMap = new Map<string, string>();
    const orgApps = apps.filter(({ organization }) => organization === orgId);
    orgApps.forEach((app) => {
      appRolesMap.set(app.id, app.role);
    });
    return appRolesMap;
  }

  static getUserRole(
    model: { interactionData: AppInteractionData[] },
    currentUserId: string,
    billingContactId: string,
    appRolesMap: Map<string, string>,
  ): ImtUserRole {
    if (currentUserId === billingContactId) {
      return ImtUserRole.BILLING_CONTACT;
    }

    if (isEmpty(model.interactionData) || appRolesMap.size === 0) {
      return ImtUserRole.MIXED;
    }

    const filteredIntData = model.interactionData.filter(({ appId }) => appRolesMap.has(appId));

    const isAdminOrCollabInAllAvailableApps = filteredIntData.every(({ appId }) => {
      const role = appRolesMap.get(appId);
      return role === 'admin' || role === 'collaborator';
    });

    if (isAdminOrCollabInAllAvailableApps) {
      return ImtUserRole.ADMIN_OR_COLLABORATOR;
    }

    const isReporterInAllAvailableApps = filteredIntData.every(({ appId }) => {
      const role = appRolesMap.get(appId);
      return role === 'reporter';
    });

    if (isReporterInAllAvailableApps) {
      return ImtUserRole.REPORTER_IN_ALL_APPS;
    }

    const isReporterInSomeApps = filteredIntData.some(({ appId }) => {
      const role = appRolesMap.get(appId);
      return role === 'reporter';
    });

    if (isReporterInSomeApps) {
      return ImtUserRole.REPORTER_IN_SOME_APPS;
    }

    return ImtUserRole.MIXED;
  }

  static getUserPermissions(
    model: { createdBy: string; interactionData: AppInteractionData[] },
    currentUserId: string,
    availableApps: string[],
  ): ImtPermisson {
    if (model.createdBy === currentUserId) {
      return ImtPermisson.CREATOR;
    }

    const modelAppIdsSet = new Set(availableApps);
    return model.interactionData.some((interaction) => !modelAppIdsSet.has(interaction.appId))
      ? ImtPermisson.HAS_ACCESS_TO_SOME_APPS
      : ImtPermisson.HAS_ACCESS_TO_ALL_APPS;
  }

  static getAvailableInteractionData(
    model: { interactionData: AppInteractionData[] },
    availableApps: OrganizationApp[],
  ): AppInteractionData[] {
    return model.interactionData.filter(({ appId }) => availableApps.some(({ id }) => id === appId));
  }

  static getPerAppCriteria({
    criteria,
    model: { interactionData },
    organizationApps,
    multipleEvents,
    unifiedTargeting,
    unifiedAttributes,
  }: {
    criteria: TargetingCriteria;
    model: { interactionData: AppInteractionData[] };
    organizationApps: OrganizationApp[];
    multipleEvents: AppEventList[];
    unifiedTargeting: CriteriaUnifiedTargeting;
    unifiedAttributes: CriteriaUnifiedAttributes;
  }): Record<string, TargetingCriteria> {
    const appIds = interactionData.map(({ appId }) => appId);
    const appsInInteraction = appIds
      .map((appId) => organizationApps.find(({ id }) => appId === id))
      .filter((app) => !!app) as OrganizationApp[];

    return Object.fromEntries(
      appsInInteraction.map((app) => [
        app.id,
        transformCriteriaForApp({ app, criteria, multipleEvents, unifiedAttributes, unifiedTargeting }),
      ]),
    );
  }

  static convertPayloadKeys(payload: any): any {
    return {
      ...camelCaseKeys(omit(['criteria', 'interaction_data', 'interactionData'], payload)),
      criteria: payload.criteria,
      interactionData: (payload.interaction_data || payload.interactionData)?.map((data: AppInteractionData) => ({
        ...camelCaseKeys(omit(['criteria'], data)),
        criteria: data.criteria,
      })),
    } as any;
  }

  static getIsDisabledBtn(isSaving: boolean, interactionData: AppInteractionData[]): boolean {
    return isSaving || interactionData.length === 0 || interactionData.every(({ isRemoved }) => isRemoved);
  }

  static getAppIdsUserHaveNoAccessTo(
    orgApps: OrganizationApp[],
    appIcons: AppIcon[],
    interactionData: AppInteractionData[] = [],
  ) {
    const orgAppsSet = new Set(orgApps.map(({ id }) => id).concat(appIcons.map(({ id }) => id)));
    const appIds = interactionData.map(({ appId }) => appId);
    return appIds.filter((appId) => !orgAppsSet.has(appId));
  }
}

function getIsUserHasReporterRole(userRole: ImtUserRole) {
  return userRole === ImtUserRole.REPORTER_IN_ALL_APPS || userRole === ImtUserRole.REPORTER_IN_SOME_APPS;
}
