import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';
import { MongoInteractionService } from 'src/services';
import { AppIcon, AppIconsState, GetAppIconsFn } from './appIcons.types';

export const initialState: AppIconsState = {
  apps: [],
  isLoading: false,
  isError: false,
};

export enum AppIconsActions {
  GET_APP_ICONS = 'GET_APP_ICONS',
}

const getAppIconsHandler: GetAppIconsFn = async ({ apps }) => {
  try {
    const data = await MongoInteractionService.getAppIcons(apps);
    return data.map((app) => ({ ...omit(['app_id'], app), id: app.app_id })) as AppIcon[];
  } catch (error) {
    throw new Error(`Error creating download: ${error}`);
  }
};

export const getAppIcons = createAsyncThunk(AppIconsActions.GET_APP_ICONS, getAppIconsHandler);

export const appIconsSlice = createSlice({
  name: 'appIcons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppIcons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAppIcons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apps = action.payload;
      })
      .addCase(getAppIcons.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
